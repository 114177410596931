import React, { useCallback, useEffect, useState, useContext, useMemo, useRef } from 'react';
import BarChart from '../components/charts/BarChart';
import Tabs from '../components/Tabs';
import Filter from '../components/Filter';
import { getNascimentos } from '../apis/apiNascimentos';
import { getPrevisoes } from '../apis/apiPrevisoes';
import { Box, Grid } from '@mui/material';
import fieldsTables from '../utils/fieldsTables';
import showColumns from '../utils/showColumns';
import { showFilters } from '../utils/configFilters';
import Table from '../components/Table';
import Badge from '../components/Badge';
import PieChartCustom from '../components/charts/PieChart';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import EmptyState from '../components/EmptyState';
import Loading from '../components/Loading';
import { LanguageContext } from '../App';
import AccordionCustom from '../components/ArcodionCustom';
import PageForPrint from '../components/PageForPrint';
import Printer from '../components/Printer';
import { getPropriedades } from '../apis/apiPropriedades';

interface Animal {
  idVisual: string ;
  idEletronico: string | null;
  dataNascimento: string;
  prevParto: string;
  idadeMeses: number;
  pesoNascimento: number;
  aniSexo: string;
  aniNumeroCab: string;
  categoria: {
      catCodigo: number;
      catNome: string;
  };
  raca: {
      espCodigo: number;
      espNome: string;
      racCodigo: number;
      racNome: string;
  };
}

interface PieChartData {
  "name": String,
  "value": Number,
  "rawData": Animal[],
}

function IndicadoresNascimentos() {
  const [loading, setLoading] = useState<boolean>(true)
  const [barChartDataByBirth, setBarChartDataByBirth] = useState<BarChartData[]>([])
  const [barChartDataByPrev, setBarChartDataByPrev] = useState<BarChartData[]>([])
  const [pieChartDataBreed, setPieChartDataBreed] = useState<PieChartData[]>([])
  const [pieChartDataSex, setPieChartDataSex] = useState<PieChartData[]>([])
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [selectedMonths, setSelectedMonths] = useState<string[]>([])
  const [allMonths, setAllMonths] = useState<string[]>([]);
  const [selectedSex, setSelectedSex] = useState<string[]>([])
  const [allSex, setAllSex] = useState<string[]>([]);
  const [selectedBreed, setSelectedBreed] = useState<string[]>([])
  const [allBreed, setAllBreed] = useState<string[]>([]);
  const [selectedAnimalsByChildBirth, setSelectedAnimalsByChildBirth] = useState<Animal[]>([])
  const [selectedAnimalsByPrev, setSelectedAnimalsByPrev] = useState<Animal[]>([])
  const [animals, setAnimals] = useState<Animal[]>([])
  const [searchParams] = useSearchParams();
  const { translations, setLanguage } = useContext(LanguageContext);
  const [datePlaceholder, setDatePlaceholder] = useState<string>("");
  const printRef1 = useRef(null);
  const printRef2 = useRef(null);
  const printRef3 = useRef(null);
  const printRef4 = useRef(null);
  const [farmName, setFarmName] = useState<string>("");
  const [filtersPrintRef, setFiltersPrintRef] = useState<FiltersPrintRefType | undefined>(undefined);

  type FiltersPrintRefType = {
    espCodigo: string;
    racCodigo: string;
    catCodigo: string;
    lotCodigo: string;
  };
  
  type FarmsType = {
    proCodigo: number;
    proDescricao: string;
  }

  const mesEnum = useMemo(() => translations ? [
    translations["lbl.react.sem.mes"],
    translations["lbl.react.jan"],
    translations["lbl.react.fev"],
    translations["lbl.react.mar"],
    translations["lbl.react.abr"],
    translations["lbl.react.mai"],
    translations["lbl.react.jun"],
    translations["lbl.react.jul"],
    translations["lbl.react.ago"],
    translations["lbl.react.set"],
    translations["lbl.react.out"],
    translations["lbl.react.nov"],
    translations["lbl.react.dez"],
  ] : [], [translations]);

  const handleAnimalsByBirthDate = (animalList: Animal[] | any) => {
    const groupedAnimals = animalList.reduce((group: any, animal: Animal) => {
      group[animal.dataNascimento.split('-')[0]+animal.dataNascimento.split('-')[1]] = group[animal.dataNascimento.split('-')[0]+animal.dataNascimento.split('-')[1]] || [];
      group[animal.dataNascimento.split('-')[0]+animal.dataNascimento.split('-')[1]].push(animal);
      return group;
    }, {})
    const groupedAnimalsSorted = Object.entries(groupedAnimals).sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
    return groupedAnimalsSorted
  }

  const handleAnimalsByChildBirthDate = (animalList: Animal[] | any) => {
    const groupedAnimals = animalList.reduce((group: any, animal: Animal) => {
      group[animal.prevParto.split('-')[0]+animal.prevParto.split('-')[1]] = group[animal.prevParto.split('-')[0]+animal.prevParto.split('-')[1]] || [];
      group[animal.prevParto.split('-')[0]+animal.prevParto.split('-')[1]].push(animal);
      return group;
    }, {})
    const groupedAnimalsSorted = Object.entries(groupedAnimals).sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
    return groupedAnimalsSorted
  }

  const handleAnimalsBySex = useCallback((animalList: Animal[] | any) => {
    const groupedAnimals = []
    const males = animalList.filter((animal: Animal) => animal.aniSexo === "M")
    if (males.length) {
      groupedAnimals.push(
        {
          name: translations["lbl.react.machos"],
          value: males.reduce((acc: number) => acc = acc + 1, 0),
          rawData: males
        }
      )
    }
    const females = animalList.filter((animal: Animal) => animal.aniSexo === "F")
    if (females.length) {
      groupedAnimals.push(
        {
          name: translations["lbl.react.femeas"],
          value: females.reduce((acc: number) => acc = acc + 1, 0),
          rawData: females
        }
      )
    }
    return groupedAnimals
  }, [translations])

  const handleAnimalsByBreed = useCallback((animalList: Animal[] | any) => {
    const groupedAnimals = animalList.reduce((group: any, animal: Animal) => {
      group[(animal.raca.racNome).trim()] = group[(animal.raca.racNome).trim()] || [];
      group[(animal.raca.racNome).trim()].push(animal);
      return group;
    }, {})

    const groupedAnimalsSorted = Object.entries(groupedAnimals)
      .sort((a: any, b: any) => (
        b[1]
        .reduce((acc: number) => acc = acc + 1, 0) - a[1]
        .reduce((acc: number) => acc = acc + 1, 0)
      ))

    const mappedData = []

    const othersLabels: string[] = []

    if (groupedAnimalsSorted.length < 6) {
      mappedData.push(...groupedAnimalsSorted)
    } else {
      mappedData.push(...groupedAnimalsSorted.reduce((acc: any, curr: any, index: number) => {
        if (index < 4) {
          acc[index] = curr
        } else {
          if (!othersLabels.includes(curr[1][0].raca.racNome)) othersLabels.push(curr[1][0].raca.racNome)
          acc[4][1] = [...acc[4][1], ...curr[1]]
        }
        return acc
      }, [[],[],[],[],[translations["lbl.react.outros"], []]]))
    }

    return mappedData.map((breed: any) => (
      {
        name: breed[0],
        value: breed[1].reduce((acc: number) => acc = acc + 1, 0),
        rawData: breed[1]
      }
    ))
  }, [translations])

  const handleTableData = useCallback(() => {
    const animalsList = animals.filter((animal) => {
      if (selectedTab === "previsao") {
        return (selectedMonths.includes(mesEnum[parseInt(animal.prevParto.substring(5,7))]+' - '+animal.prevParto.substring(2,4)))
      } else {
        const sex = animal.aniSexo === "M" ? translations["lbl.react.macho"] : animal.aniSexo === "F" ? translations["lbl.react.femea"] : translations["lbl.react.indefinido"]
        return (
          selectedMonths.includes(mesEnum[parseInt(animal.dataNascimento.substring(5,7))]+' - '+animal.dataNascimento.substring(2,4))
          && (selectedSex.includes(sex)) && selectedBreed.includes(animal.raca.racNome)
        )
      }
    })
   
    if (selectedTab === "previsao") setSelectedAnimalsByPrev(animalsList)
    else setSelectedAnimalsByChildBirth(animalsList)

  }, [animals, mesEnum, selectedBreed, selectedMonths, selectedSex, selectedTab, translations])

  const handleSelectBars = () => {
  }

  const handleSelectPie = () => {
  }

  const handleDelete = (label: string) => {
    if (selectedMonths.includes(label)) {
      if (selectedMonths.length > 1) {
        const monthsList = selectedMonths.filter(months => months !== label);
        setSelectedMonths(monthsList);
      }
    } else {
      setSelectedMonths((prevState) => [...prevState, label]);
    }
    if (selectedSex.includes(label)) {
      if (selectedSex.length > 1) {
        const sexList = selectedSex.filter(sex => sex !== label);
        setSelectedSex(sexList);
      }
    } else {
      setSelectedSex((prevState) => [...prevState, label]);
    }
    if (selectedBreed.includes(label)) {
      if (selectedBreed.length > 1) {
        const breedList = selectedBreed.filter(breed => breed !== label);
        setSelectedBreed(breedList);
      }
    } else {
      setSelectedBreed((prevState) => [...prevState, label]);
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, value: string) => {
    setBarChartDataByBirth([])
    setBarChartDataByPrev([])
    setPieChartDataBreed([])
    setSelectedMonths([])
    setSelectedBreed([])
    setSelectedSex([])
    setAnimals([])
    if (value === "previsao") setDatePlaceholder(translations["lbl.react.data.previsao"])
    else setDatePlaceholder(translations["lbl.react.data.nascimento"])
    setSelectedTab(value);
  };

  const handleGetAnimals = useCallback(async (filters: FiltersType) => {
    setLoading(true)
    const proCodigo = searchParams.get('pro_codigo');

    const params = {
      proCodigo: proCodigo,
      dataIni: filters.startDate,
      dataFim: filters.endDate,
      espCodigo: filters.espCodigo,
      racCodigo: filters.racCodigo,
      sexo: filters.sexo,
      lotCodigo: filters.lotCodigo,
      catCodigo: filters.catCodigo,
      statusCria: filters.statusCria
    }

    const token = searchParams.get('token')

    const result = selectedTab === "nascimentos" ? await getNascimentos(params, token) : await getPrevisoes(params, token)

    const farmsResult = await getPropriedades(proCodigo, token).then((data: any) => data.filter((currFarm: FarmsType) => currFarm.proCodigo === Number(proCodigo))[0].proDescricao);
    setFarmName(farmsResult);

    if (result != null) {
      setAnimals(result)
      let groupedAnimaisByDate = []
      if (selectedTab === "nascimentos") {
        groupedAnimaisByDate = handleAnimalsByBirthDate(result);
        const groupedAnimaisByBreed = handleAnimalsByBreed(result)
        const groupedAnimaisBySex = handleAnimalsBySex(result)
        setPieChartDataBreed(groupedAnimaisByBreed)
        setPieChartDataSex(groupedAnimaisBySex)
        setSelectedAnimalsByChildBirth(result)
      } else {
        groupedAnimaisByDate = handleAnimalsByChildBirthDate(result);
        setSelectedAnimalsByPrev(result)
      }
      const mappedData = groupedAnimaisByDate.map((group: Animal[] | any) => (
        {
          "xData": mesEnum[parseInt(group[0].substring(4,6))]+' - '+group[0].substring(2,4),
          "yData": selectedTab === "nascimentos" ? group[1].reduce((acc: number, curr: Animal) => acc = acc + 1, 0) : group[1].length,
          "rawData": group[1],
          "name": translations["lbl.react.animais"],
          "color": "#607d8b"
        }
      ))
      const months = mappedData ? mappedData.map((data: { xData: string; }) => data.xData) : [];
      const breeds = result.map((animal:Animal) => animal.raca.racNome) || [];
      const uniqueBreeds = breeds.reduce((acc: string[], breed: string) => acc.includes(breed) ? acc : [...acc, breed], []);
      const sexs = result.map((animal:Animal) => animal.aniSexo === "M" ? translations["lbl.react.macho"] : animal.aniSexo === "F" ? translations["lbl.react.femea"] : "Indefinido" ) || [];
      const uniqueSexs = sexs.reduce((acc: string[], sex: string) => acc.includes(sex) ? acc : [...acc, sex], []);
      setSelectedMonths(months);
      setAllMonths(months);
      setSelectedSex(uniqueSexs);
      setAllSex(uniqueSexs);
      setSelectedBreed(uniqueBreeds);
      setAllBreed(uniqueBreeds);

      setFiltersPrintRef({
        'espCodigo': translations["lbl.react.filter.especie"],
        'racCodigo': translations["lbl.react.filter.raca"],
        'catCodigo': translations["lbl.react.filter.categoria"],
        'lotCodigo': translations["lbl.react.filter.lote"],
      });

      if (selectedTab === "nascimentos") setBarChartDataByBirth(mappedData);
      else setBarChartDataByPrev(mappedData);
    }

    setLoading(false)
    
  }, [searchParams, selectedTab, translations, mesEnum, handleAnimalsByBreed, handleAnimalsBySex]);

  const handleSearchButton = (filters: FiltersType) => {
    const mappedFilters = {
      ...filters,
      startDate: filters.startDate ? dayjs(filters.startDate).format("YYYY-MM-DD") : "",
      endDate: filters.endDate ? dayjs(filters.endDate).add(1, 'months').subtract(1, 'days').format("YYYY-MM-DD") : ""
    }
    handleGetAnimals(mappedFilters)
  }

  const handleCloseTable = (name: string) => {
    if (name === "previsao" || name === "nascimentos") setSelectedMonths([])
    if (name === "breed") setSelectedBreed([])
    if (name === "sex") setSelectedSex([])
  }

  useEffect(() => {

    if (selectedTab !== "") {
      handleGetAnimals({
        startDate: '',
        endDate: '',
        espCodigo: '',
        racCodigo: '',
        sexo: '',
        lotCodigo: '',
        catCodigo: '',
        statusCria: ''
      });
    }

  }, [handleGetAnimals, selectedTab]);

  useEffect(() => {

    const initialTab = searchParams.get('indicador') || "previsao"
    const languageParam = searchParams.get('language') || 'pt'
    setLanguage(languageParam)
    setSelectedTab(initialTab)

  }, [searchParams, setLanguage]);

  useEffect(() => {
    handleTableData()

  }, [handleTableData, translations]);

  return (
    <Grid container justifyContent="center">
      { translations &&
        <Grid item xs={12}>
          <div className="page-body">
            <span className="page-title">
              { translations["lbl.react.indicadores.nascimentos"] }
            </span>
            <Grid container rowSpacing={2} justifyContent="center">
              <Grid item xs={12}>
                { selectedTab &&
                  <Tabs handleChange={handleChangeTab} value={selectedTab} labels={[{ label: translations["lbl.react.previsao.partos.maiuscula"], name: "previsao" }, { label: translations["lbl.react.nascimentos.maiuscula"], name: "nascimentos" }]}/>
                }
              </Grid>
              <Grid item xs={12}>
                <Filter
                  handleSearch={handleSearchButton}
                  datePlaceholder={datePlaceholder}
                  selectedTab={selectedTab}
                  showFilters={selectedTab === "nascimentos" ? showFilters.nascimentos : showFilters.partos}
                  onlyMonths={true}
                  dateFormat="MM/yyyy"
                />
              </Grid>
              { loading === false ?
                <Grid item xs={12}>
                  {selectedTab === "previsao" && (
                    animals.length ?
                      <div>
                        <PageForPrint reference={printRef1} farmName={farmName} chartTitle={translations["lbl.react.previsao.partos"]} usedFilters={filtersPrintRef}>
                          <Grid container className="chart-container">
                            <Grid item xs={12}>
                              <AccordionCustom
                                title={translations["lbl.react.previsao.partos"]}
                                subtitle={translations["lbl.react.clique.meses.visualizar.tabela"]}
                                amountText={translations["lbl.react.partos.previstos.intervalo"]}
                                intervalTotal={barChartDataByPrev ? barChartDataByPrev.reduce((acc: number, previous: BarChartData) => acc = acc + previous.yData, 0) : 0}
                                printButton={<Printer componentRef={printRef1} chartTitle={translations["lbl.react.previsao.partos"]} />}
                              >
                                <Grid container item xs={12} pb={2} mb={3}>
                                  <BarChart
                                    data={barChartDataByPrev}
                                    barLabel={translations["lbl.react.animais"]}
                                    sideLabel={translations["lbl.react.quantidade.animais"]}
                                    handleSelectBar={handleSelectBars}
                                  />
                                </Grid>
                              </AccordionCustom>
                            </Grid>
                            { selectedMonths.length > 0 &&
                              <Box className="hide-to-print" sx={{ width: "100%", backgroundColor: "#efefef", border: "1px solid #e7ebee", borderRadius: "3px", margin: "0 16px 16px 16px"} }>
                                <Grid item container p={2}>
                                  <Grid item xs={12} mb={2}>
                                    <span className="c-b-cinza-icons chart-warning">{translations["lbl.react.para.ocultar.ou.exibir.clique"] + "."}</span>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <span>{translations["lbl.react.meses.selecionados"]}</span>
                                  </Grid>
                                  <Grid item container xs={12} mt={2} columnSpacing={2}>
                                    {
                                      allMonths.map((month) => <Badge label={month} handleDelete={handleDelete} key={month} deletable={selectedMonths.length !== 1 || !selectedMonths.includes(month)} addOrDel={selectedMonths.includes(month)} />)
                                    }
                                  </Grid>
                                </Grid>
                              </Box>
                            }
                          </Grid>
                        </PageForPrint>
                        { selectedMonths.length > 0 &&
                          <Table
                            columns={fieldsTables(translations).partos}
                            rows={selectedAnimalsByPrev}
                            idColumn='aniCodigo'
                            sortColumn='prevParto'
                            tableTitle={translations["lbl.react.dados.para.meses.selecionados"]}
                            handleCloseTable={handleCloseTable}
                            name="previsao"
                            showColumns={showColumns.partos}
                          />
                        }
                      </div>
                    :
                      <div>
                        <EmptyState />
                      </div>
                    )
                  }
                  {selectedTab === "nascimentos" && (
                    animals.length ?
                      <div>
                        <div>
                          <PageForPrint reference={printRef2} farmName={farmName} onlyMonths={true} chartTitle={translations["lbl.react.nascimentos"]} usedFilters={filtersPrintRef}>
                            <Grid container className="chart-container">
                              <Grid item xs={12}>
                                <AccordionCustom
                                  title={translations["lbl.react.nascimentos"]}
                                  subtitle={translations["lbl.react.clique.meses.visualizar.tabela"]}
                                  amountText={translations["lbl.react.nascimentos.intervalo"]}
                                  intervalTotal={barChartDataByBirth ? barChartDataByBirth.reduce((acc: number, previous: BarChartData) => acc = acc + previous.yData, 0) : 0}
                                  printButton={<Printer componentRef={printRef2} chartTitle={translations["lbl.react.previsao.partos"]} />}
                                >
                                  <Grid container item xs={12} pb={2} mb={3}>
                                    <BarChart
                                      data={barChartDataByBirth}
                                      barLabel={translations["lbl.react.animais"]}
                                      sideLabel={translations["lbl.react.quantidade.animais"]}
                                      handleSelectBar={handleSelectBars}
                                    />
                                  </Grid>
                                </AccordionCustom>
                              </Grid>
                            </Grid>
                          </PageForPrint>
                        </div>
                        <Grid item container mt={2}>
                          <Grid item xs mr={2} justifyContent="center">
                            <PageForPrint reference={printRef3} farmName={farmName} onlyMonths={true} chartTitle={translations["lbl.react.nascimentos.x.sexo"]} usedFilters={filtersPrintRef}>
                              <PieChartCustom
                                data={pieChartDataSex}
                                title={translations["lbl.react.nascimentos.x.sexo"]}
                                subtitle={translations["lbl.react.clique.sexo.visualizar.tabela"]}
                                handleSelectBar={handleSelectPie}
                                name="sex"
                                hardCodedQuantityXWeight={true}
                                componentRefPrint={printRef3}
                              />
                            </PageForPrint>
                          </Grid>
                          <Grid item xs justifyContent="center">
                            <PageForPrint reference={printRef4} farmName={farmName} onlyMonths={true} chartTitle={translations["lbl.react.nascimentos.x.raca"]} usedFilters={filtersPrintRef}>
                              <PieChartCustom
                                data={pieChartDataBreed}
                                title={translations["lbl.react.nascimentos.x.raca"]}
                                subtitle={translations["lbl.react.clique.raca.visualizar.tabela"]}
                                handleSelectBar={handleSelectPie}
                                name="breed"
                                hardCodedQuantityXWeight={true}
                                componentRefPrint={printRef4}
                              />
                            </PageForPrint>
                          </Grid>
                          { selectedMonths.length > 0 &&
                            <Grid item xs={12} >
                                <Box sx={{ width: "100%", backgroundColor: "#efefef", border: "1px solid #e7ebee", borderRadius: "3px", marginTop: "16px"}}>
                                  <Grid item container p={2}>
                                    <Grid item xs={12} mb={2}>
                                      <span className="c-b-cinza-icons chart-warning">{translations["lbl.react.para.ocultar.ou.exibir.clique"] + "."}</span>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <span>{translations["lbl.react.meses.selecionados"]}</span>
                                    </Grid>
                                    <Grid item container xs={12} mt={2} columnSpacing={2}>
                                      {
                                        allMonths.map(month => <Badge label={month} handleDelete={handleDelete} key={month} deletable={selectedMonths.length !== 1 || !selectedMonths.includes(month)} addOrDel={selectedMonths.includes(month)} />)
                                      }
                                    </Grid>
                                    <Grid item container xs={12} mt={2}>
                                      <Grid item container xs={3}>
                                        <Grid item xs={12}>
                                          <span>{translations["lbl.react.sexos.selecionados"]}</span>
                                        </Grid>
                                        <Grid item container xs={12} mt={2} columnSpacing={2}>
                                          {
                                            allSex.map(sex => <Badge label={sex} handleDelete={handleDelete} key={sex} deletable={selectedSex.length !== 1 || !selectedSex.includes(sex)} addOrDel={selectedSex.includes(sex)} />)
                                          }
                                        </Grid>
                                      </Grid>
                                      <Grid item container xs={9}>
                                        <Grid item xs={12}>
                                          <span>{translations["lbl.react.racas.selecionadas"]}</span>
                                        </Grid>
                                        <Grid item container xs={12} mt={2} columnSpacing={2}>
                                          {
                                            allBreed.map(breed => <Badge label={breed} handleDelete={handleDelete} key={breed} deletable={selectedBreed.length !== 1 || !selectedBreed.includes(breed)} addOrDel={selectedBreed.includes(breed)} />)
                                          }
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Table
                                  columns={fieldsTables(translations).nascimentos}
                                  rows={selectedAnimalsByChildBirth}
                                  idColumn='aniCodigo'
                                  sortColumn='dataNascimento'
                                  tableTitle={translations["lbl.react.dados.informacoes.selecionadas"]}
                                  handleCloseTable={handleCloseTable}
                                  name="nascimentos"
                                  showColumns={showColumns.nascimentos}
                                />
                            </Grid>
                          }
                        </Grid>
                      </div>
                    :
                      <div>
                        <EmptyState />
                      </div>
                    )
                  }
                </Grid>
                :
                <Loading />
              }
            </Grid>
          </div>
        </Grid>
      }
    </Grid>
  );
}

export default IndicadoresNascimentos;