import { Grid, Tooltip } from '@mui/material';
import '../../assets/css/genericSummary.scss';
import InfoIcon from '@mui/icons-material/Info';

type TooltipType = [number, string, number?][];

interface HeaderData {
  tooltips: TooltipType[];
  data: Array<string | number>;
}

interface ChartData {
  headerData: HeaderData;
  contentData: Array<string | number | Array<string | number>>;
  dataTooltips?: TooltipType[];
}

interface GenericSummaryProps {
  chartData: ChartData;
  skipFirstHeader?: boolean;
  tooltipIcon?: boolean;
}

export default function GenericSummary(props: GenericSummaryProps) {
  const keyForMap = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  const { chartData, skipFirstHeader, tooltipIcon = true } = props;
  //  chartData deve ser um objeto no seguinte formato:
  //  {
  //    headerData: {
  //      tooltips: [ [index, textoDoTooltip], [index, textoDoTooltip] ],
  //      data: [ value, value, value, value, value ]
  //    },
  //    contentData: [ 
  //      [ nome, value, value, value, value... ], 
  //      [ nome, value, value, value, value... ]... 
  //    ],
  //    dataTooltips: [ 
  //      [indexDoSubArray, textoDoTooltip, indexDoContentData], 
  //      [indexDoSubArray, textoDoTooltip, indexDoContentData]...
  //    ]
  //  }
  //
  //  Os dados são um array com subarrays que correspondem a cada linha da tabela.
  //  Os tooltips são uma tupla no formato: [index, textoDoTooltip].

  const getHeaderTooltipIfExists =  (index: number) => {
    let result = chartData.headerData.tooltips.find((item: any) => item[0] === index);
    return result ? result[1] : false;
  }

  const getDataTooltipIfExists =  (itemIndex: number, rowIndex: number) => {
    let result = chartData.dataTooltips ? chartData.dataTooltips.find((item: any) => {
      return item[0] === itemIndex && item[2] === rowIndex
    }) : false;
    return result ? result[1] : false;
  }

  return (
    <Grid sx={{ maxWidth: '100%', minWidth: 500 }}>
      <table aria-label="custom table" className='generic-summary'>
        <thead>
          <tr>
            { skipFirstHeader && <th className='table-upper-head-no-border' key={'emptyHeaderCell01'}>{<>&nbsp;</>}</th> }
            { chartData && chartData.headerData && chartData.headerData.data.map((item: string | number, index: number) => {
              const key = item.toString() + index + keyForMap;
              const hasTooltip = getHeaderTooltipIfExists(index);
              
              if (hasTooltip) {
                return <th className="br"><Tooltip key={key} title={hasTooltip}><span>{item} {tooltipIcon && <InfoIcon fontSize="small" className="info-icon" />}</span></Tooltip></th>;
              } else {
                return <th className="br" key={key}>{item}</th>;
              }
            })}
          </tr>
        </thead>
        <tbody>
          { chartData && chartData.contentData && chartData.contentData.map((row: any, rowIndex: number) => (
            <tr key={String(row[0]) + keyForMap}>
              {row.map((value: string | number, index: number) => {
                const key = index + String(value) + keyForMap;
                if (index === 0) {
                  const hasTooltip = getDataTooltipIfExists(index, rowIndex);

                  if (hasTooltip) {
                    return <td className="row-first-value br"><Tooltip key={key} title={hasTooltip}><span>{value} {tooltipIcon && <InfoIcon fontSize="small" className="info-icon" />}</span></Tooltip></td>;
                  } else {
                    return (<td className='row-first-value br' key={key}>{value}</td>);
                  }
                } else {
                  const hasTooltip = getDataTooltipIfExists(index, rowIndex);
              
                  if (hasTooltip) {
                    return <td className="br"><Tooltip key={key} title={hasTooltip}><span>{value} {tooltipIcon && <InfoIcon fontSize="small" className="info-icon" />}</span></Tooltip></td>;
                  } else {
                    return <td className="br" key={key}>{value}</td>;
                  }
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </Grid>
  );
}